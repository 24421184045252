import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/copy.png';
const _withScopeId = n => (_pushScopeId("data-v-81fcb398"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "base-mes"
};
const _hoisted_2 = {
  class: "name"
};
const _hoisted_3 = {
  class: "btns-bar"
};
const _hoisted_4 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  const _component_el_tooltip = _resolveComponent("el-tooltip");
  const _component_el_card = _resolveComponent("el-card");
  return _openBlock(), _createBlock(_component_el_card, {
    onClick: $setup.toLink,
    class: "card-mes",
    shadow: "hover"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_withDirectives(_createVNode(_component_el_image, {
      src: $props.mes.iconPath,
      lazy: "",
      class: "logo"
    }, null, 8, ["src"]), [[_vShow, _ctx.iconFlag]]), _createElementVNode("span", _hoisted_2, _toDisplayString($props.mes.name), 1)]), _withDirectives(_createElementVNode("div", {
      class: "introduction"
    }, _toDisplayString($props.mes.introduction), 513), [[_vShow, _ctx.introFlag]]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_tooltip, {
      content: "复制链接",
      placement: "top"
    }, {
      default: _withCtx(() => [_createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => $setup.iconEvent(1), ["stop"])),
        src: _imports_0,
        class: "btn-icon"
      })]),
      _: 1
    }), _createVNode(_component_el_tooltip, {
      content: $setup.collText,
      placement: "top"
    }, {
      default: _withCtx(() => [_createElementVNode("img", {
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => $setup.iconEvent(2), ["stop"])),
        src: $setup.collSrc,
        class: "btn-icon"
      }, null, 8, _hoisted_4)]),
      _: 1
    }, 8, ["content"])])]),
    _: 1
  }, 8, ["onClick"]);
}