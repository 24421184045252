import { toRefs, computed } from 'vue';
import { copyStr } from '@/utils/textCtr';
import { ElMessage } from 'element-plus';
import { useNavPathStore } from '@/store/modules/navPath';
export default {
  props: {
    mes: {
      type: Object,
      required: true
    },
    controlFlags: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useNavPathStore();
    const isColl = computed(() => store.localCollArr.some(item => item.url === props.mes.url));
    const collText = computed(() => isColl.value ? '取消收藏' : '收藏');
    const collSrc = computed(() => isColl.value ? require('@/assets/images/coll-a.png') : require('@/assets/images/coll.png'));
    function toLink() {
      open(props.mes.url);
    }
    function iconEvent(num) {
      if (num === 1) {
        copyStr(props.mes.url);
        ElMessage({
          type: 'success',
          message: '链接已复制！'
        });
      }
      if (num === 2) {
        store.setCollArr(props.mes);
      }
    }
    return {
      collSrc,
      collText,
      toLink,
      iconEvent,
      ...toRefs(props.controlFlags)
    };
  }
};